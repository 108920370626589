import React, { ReactNode, createContext, useEffect, useState } from "react";
import { selectIsSimpleCreatorOpen } from "~/features/nav/selectors.ts";
import useAppSelector from "~/hooks/use-app-selector.ts";
import { isFeatureFlagEnabled } from "~/utils/feature-flags.ts";

const Context = createContext<string | undefined>(undefined);

type GorgiasProviderProps = {
	readonly id: string;
	readonly children: ReactNode;
};

function GorgiasProvider({ id, children }: GorgiasProviderProps) {
	// Gorgias
	useEffect(() => {
		if (isFeatureFlagEnabled("gorgias")) {
			const script = document.createElement("script");
			script.setAttribute("id", "gorgias-chat-widget-install-v3");
			script.setAttribute(
				"src",
				`https://config.gorgias.chat/bundle-loader/${id}`,
			);
			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, [id]);

	// Wait for container
	const [chatContainer, setChatContainer] = useState<HTMLElement | undefined>(
		undefined,
	);
	useEffect(() => {
		let checkTimeoutId: number | undefined;
		function checkForChatContainer() {
			const chatContainer = document.getElementById("gorgias-chat-container");
			if (chatContainer) {
				setChatContainer(chatContainer);
			} else {
				checkTimeoutId = window.setTimeout(checkForChatContainer, 100);
			}
		}
		checkForChatContainer();

		return () => {
			window.clearTimeout(checkTimeoutId);
		};
	}, []);

	// If simple creator, hide chat
	const isSimpleCreatorOpen = useAppSelector(selectIsSimpleCreatorOpen);
	useEffect(() => {
		if (!chatContainer) {
			return;
		}
		chatContainer.style.display = isSimpleCreatorOpen ? "none" : "block";
	}, [isSimpleCreatorOpen, chatContainer]);

	return <Context.Provider value={id}>{children}</Context.Provider>;
}

export default GorgiasProvider;
