function isFeatureFlagEnabled(name: string) {
	try {
		const flags = JSON.parse(import.meta.env.VITE_FEATURE_FLAGS);
		return flags.includes(name);
	} catch (e) {
		return false;
	}
}

export { isFeatureFlagEnabled };
