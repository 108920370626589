import formatLongInteger from "./format-long-integer.ts";

function toWholeNumberFallbackToFixed(
	num: number,
	fractionDigits: number,
): string {
	if (Math.round(num) === num) {
		return formatLongInteger(num);
	}

	const wholeAmount = Math.floor(num);
	const fractionAmount = num - wholeAmount;
	return (
		formatLongInteger(wholeAmount) +
		fractionAmount.toFixed(fractionDigits).substring(1)
	);
}

export default toWholeNumberFallbackToFixed;
